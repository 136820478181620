.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.checkInQuestion {
    padding-top: 20px;

}

/*$ampt-volt : #CEFF00;*/
/*$background-color: black;*/

app-check-in, body {
    /*background-color: black !important;*/
}

.container {
    padding-top: 10px;
    /*background-color: black;*/
}

.form-header {
    background-color: black;
    width: 100%;
}

.form-img-header {
    background-color: black;
    padding: 0;
    border-bottom: 0;
}

.input-full-width {
    width: 100%;
    color: #CEFF00;
}


.error {
    color: red;
}


.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;
}

.required {
    color: red;
    font-weight: bold;
    display: inline;
    padding-left: 5px;
}

.weight_warning {
    color: red;
}

.card {
    background-color: black;
    color: #CEFF00;
    border:3px solid #CEFF00;
}

.card-header-check-in {
    background-color: black;
    color: #CEFF00;
    border-bottom:3px solid #CEFF00 !important;
    border-top: none !important;
    border-left: none;
    border-right: none;
}

p, mat-label, mat-hint, mat-form-field, mat-slider, mat-slider-wrapper {
    color: #CEFF00;
}

.mat-slider-track-fill {
    background-color: #CEFF00;
}

button, .btn, .btn-primary {
    color: #CEFF00 !important;
    background-color: black !important;
    border: 1px solid #CEFF00 !important;
}